<template>
  <v-container grid-list-md fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm7 md5>
        <v-card>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <Ffly4uAuthLogo />
              <DropdownTranslate />
              <h1>{{ $t('auth.passwordReset.title') }}</h1>
              <p>
                {{ $t('auth.passwordReset.descriptionAlt') }}
              </p>
              <v-form
                ref="form"
                v-model="validForm"
                v-on:submit.prevent="reset"
              >
                <v-text-field
                  v-model="username"
                  :label="$t('auth.username')"
                  :rules="[rules.username]"
                  backgroundColor="white"
                  solo
                  append-icon="mdi-account-outline"
                ></v-text-field>
                <v-layout align-center justify-center row fill-height>
                  <v-btn
                    :disabled="!validForm"
                    @click="validate()"
                    type="submit"
                    class="white--text"
                    color="primary"
                  >
                    {{ $t('auth.passwordReset.link') }}
                  </v-btn>
                </v-layout>
                <div class="my-6">
                  <p class="error--text" v-if="error">{{ $t('auth.passwordReset.errorAlt') }}</p>
                </div>
                <div class="my-6">
                  <v-layout align-end justify-space-around row fill-height>
                    <router-link :to="{name: 'login'}">
                      {{ $t('auth.passwordReset.rememberingPassword') }}
                    </router-link>
                    <router-link :to="{name: 'reset-password'}">
                      {{ $t('auth.passwordReset.title') }}
                    </router-link>
                  </v-layout>
                </div>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Ffly4uAuthLogo from '@/components/Ffly4uAuthLogo.vue';

export default {
  name: 'Reset',
  components: { Ffly4uAuthLogo },
  data() {
    return {
      validForm: true,
      username: '',
      error: false,
      rules: {
        username: (v) => (v.length >= 1 && v.length <= 128) || this.$t('misc.invalidUsername'),
      },
    };
  },
  methods: {
    /**
    * Ensure that form is valid before making backend request
    */
    validate() {
      this.error = false;
      if (this.$refs.form.validate()) {
        this.reset();
      }
    },
    async reset() {
      try {
        await this.$store
          .dispatch('auth/RESET', {
            username: this.username,
          });
        this.$router.push('/email-sent');
      } catch (error) {
        this.error = error.message;
      }
    },
  },
  metaInfo() {
    return {
      title: this.$t('auth.passwordReset.title'),
    };
  },
};
</script>
